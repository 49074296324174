.ticket-item {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
}

.ticket-image {
  height: 75px;
  width: 75px;
  margin-left: 8px;
  border-radius: 10px;
  margin-block: 10px;
  margin-right: 5px;
}

.ticket-name {
    color: black;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 3vw;   
}


.descriptiontwoline {
  display: block;
  overflow: hidden;
  max-height: 2.6em;
  line-height: 1.3em;
  margin-top: 5px
}

.descriptionProceed {
  display: flex;
  line-height: 1.5em;
  font-size: 11px;
  bottom: 0px;
}

/* .modernWay {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2; 
  -webkit-box-orient: vertical;
} */

/* .ticket-left-arrow {
  stroke-width: "2px";
} */

.container-scrol {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.left-scrol {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.is-disabled
{
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.125);
  opacity: 0.8;
}
