@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800&display=swap');
/* Header css */
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@900&display=swap');
.fontloader {
  font-family: 'Nunito Sans', sans-serif;
}

.backgound-header {
  background-color: #000000;
  font-family: 'Nunito Sans', sans-serif;
  height: 65px;
}

.bookingsite_header {
  width: 165px;
  height: 37px;
  border-radius: 10px;
  border: solid 1px #ffffff;
  display: inline-block;
  /* margin-top: 17px; */
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 6px;
  /* margin-right: 5px; */
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
}

/* footer CSS */
.bookingsite_footer {
    border-radius: 10px;
    /* border: solid 1px #ffffff; */
    display: inline-block;
    /* color:  #ffffff; */
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Nunito Sans', sans-serif;
    background-color: #EF7F1A;
    /* background-image: linear-gradient(#ffcb52, #ff7b02); */
}

.gradientcolor{
  background-image: linear-gradient(#ffcb52, #ff7b02);
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  /* margin-top: 20px; */
 }


/* Home page css */

.coverimage {
  width: 85%;
  margin: 25px 3px 0px 30px;
    border-radius: 10px;
}


.bottomsheet-memeberbenefit{
  background-color: #cecdcb;
  border-radius: 15px;
  margin-top: 16px;
  padding: 15px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans', sans-serif;
}



/* .rounded {
  border-radius: 25px;
  margin: 5px;
} */

.image-container {
  float: left;
  height: 135px;
  width: 135px;
  margin-right: 30px;
  margin-top: 15px;
  font-family: 'Nunito Sans', sans-serif;
  margin: 20px;
}

.festContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.festbutton {
  background-color: #0b98af;
  color: white;
  width: 100px;
  border-radius: 20px;
  font-size: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

/* .sponsorbutton {
  background-color: #0b98af;
  color: white;
  width: 180px;
  border-radius: 20px;
  font-size: 20px;
  font-family: 'Nunito Sans', sans-serif;
  padding: 5px;
} */


/* Thankyou page css */

.checkoutbody {
  background-color: #1e9ab0;
  color: white;
  text-align: center;
  padding-top: 25px;
  font-family: 'Nunito Sans', sans-serif;
}

.imagecheckout {
  width: 200;
  height: 236;
}

.imgthumbnai_new {
  background-color: #ee7e1a;
  margin-top: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkout-Areyou {
  width: 150px;
  height: 37px;
  /* border-radius: 10px; */
  /* border: solid 1px #ffffff; */
  display: inline-block;
  margin-top: 17px;
  /* margin-bottom: 17px; */
  color: #ffffff;
  padding-left: 30px;
  padding-top: 7px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkout-share {
  width: 130px;
  height: 37px;
  border-radius: 10px;
  border: solid 1px #ffffff;
  display: inline-block;
  margin-top: 17px;
  /* margin-bottom: 17px; */
  color: #ffffff;
  padding-left: 30px;
  padding-top: 7px;
  margin-bottom: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkout-titletext {
  width: 315px;
  height: 37px;
  padding-bottom: 40px;
  display: inline-block;
  margin-top: 17px;
  color: #ffffff;
  padding-left: 30px;
  padding-top: 7px;
  font-family: 'Nunito Sans', sans-serif;
}

.booktickets {
  /* background-color: #0b98af; */
  background-color: black;
  color: white;
  padding-bottom: 15px;
  padding-top: 35px;
  font-family: 'Nunito Sans', sans-serif;
}

.book-details {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  color: black;
  border-radius: 10px;
  margin-top: 15px;
  padding: 15px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkout-user-details{
  background-color: white;
  color: black;
  border-radius: 10px;
  margin-top: 15px;
  padding: 15px;
  font-family: 'Nunito Sans', sans-serif;
}

.hrnew1 {
  border: 2px solid #0b98af;
  margin: 10px;
  color: #0b98af!important;
  width: 90%;
  margin-left: 20px;
}

.bookticket_submit {
  width: 170px;
  height: 37px;
  border-radius: 10px;
  border: solid 1px #ffffff;
  display: inline-block;
  margin-top: 17px;
  color: #ffffff;
  margin-top: 12%;
  padding-top: 7px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  margin-left: 25%;
}

.titleBookTicket{
  width: 170px;
  height: 37px;
  /* border-radius: 10px; */
  /* border: solid 1px #ffffff; */
  display: inline-block;
  margin-top: 17px;
  color: #ffffff;
  margin-top: 12%;
  padding-top: 7px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  margin-left: 25%;
}

.checkout_submit {
  width: 170px;
  height: 37px;
  border-radius: 10px;
  display: inline-block;
  /* margin-top: 17px; */
  color: #ffffff;
  margin-top: 5%;
  padding-top: 7px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkouttickit{
  text-align: justify;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inputNameContainer {
  width: '100%';
  height: auto;
}

.event-title {
  font-family: 'Nunito Sans';
  font-weight: bold;
  font-size: larger;
  margin-top: 6px;
  margin-bottom: 6px;
}

.fontFamily {
  font-family: 'Nunito Sans';
  color: black;
  font-weight: 600;
}

.bookTicketsTitle {
  font-family: 'Nunito Sans';
  color: black;
  font-weight: 400;
  font-size: 12px;
}

.bookTicketsText {
  font-family: 'Nunito Sans';
  color: black;
  font-weight: 600;
  font-size: 12px;
}

.footerText {
  font-family: 'Nunito Sans';
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.event-description {
  line-height: 25px;
  text-align: justify;
  margin-left: 8px;
  margin-right: 8px;
  font-family: 'Nunito Sans';
  color: black;
  font-weight: 400;
}

.input-group{
  margin-top: 15px;
 }



 /* checkout view details */
/* 
 @media only screen and (max-width: 425px){
	
  .book-details-img {
    width: 40px;
  }
}

@media only screen and (max-width: 600px) {
 
  .book-details-img {
    width: 40px;
  }
}

@media only screen and (max-width: 1200px){

  .book-details-img {
    width: 40px;
  }
} */

.book-details-img {
  width: 50px;
  height: 50px;
}

.ticket-credit-container {
    border-color: white;
    border: 1px solid;
    border-radius: 6px;
    margin: 10px 40px;
    background-image: linear-gradient(#ffcb52, #ff7b02);
    padding: 8px;
}

.ticket-event-user {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  border-color: white;
}

.ticket-event-user-txt {
  padding-left: 10px;
}

/* .ticket-event-user-group {
} */

.scrollbottomsheet {
  overflow: scroll;
  background-color: #ffcb52;
}


.add-class-for-height {
  height: 100%;
  overflow: scroll;
}